<template>
    <BT-Blade-Item
        bladeName="supplier-consignment"
        :canDelete="false"
        :canEdit="false"
        :loadingMsg="loadingMsg"
        navigation="supplier-consignments"
        title="Delivery">
        <template v-slot="{ item }">
            <v-row no-gutters>
                <v-col cols="12" class="d-flex justify-center my-4">
                    <v-avatar rounded>
                        <v-img :src="companyLogoURL(item.sellerID)">
                            <template v-slot:placeholder>
                                <v-icon class="primary--text" size="48">mdi-account-outline</v-icon>
                            </template>
                        </v-img>
                    </v-avatar>
                </v-col>
                <v-col cols="6" sm="3" class="d-flex justify-center align-center">
                    <BT-Field-Address
                        label="FROM"
                        v-model="item.departureLocation" />
                </v-col>
                <v-col cols="6" sm="3" class="d-flex justify-center align-center">
                    <BT-Field-Address
                        label="TO"
                        v-model="item.destinationLocation" />
                </v-col>
                <v-col cols="6" sm="3">
                    <BT-Field-String
                        v-model="item.consignmentNumber"
                        label="Consignment #" />
                </v-col>
                <v-col cols="6" sm="3">
                    <BT-Field-Date
                        v-model="item.dueDate"
                        label="Due On" />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-divider class="my-1" />
                    <div class="px-4">Packages</div>
                    <v-card v-for="(pItem, i) in item.packagedItems" :key="i" dense flat>
                        <v-list-item dense>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span>{{ pItem.quantity | toDisplayNumber }}</span>
                                    <span class="mx-1">x</span>
                                    <span>{{ pItem.measurementStandard || 'Package' }}</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ pItem | toMeasurementLine }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-col>
                <!-- <v-col cols="12" md="6" class="d-flex justify-center align-center flex-column">
                    <v-divider class="my-1" />
                    <div v-if="item.isBuyerCourier">
                        <v-alert v-if="item.isOrdered" type="success" dense shaped class="ma-0 pa-1">
                            <span v-if="item.isRequestPickup">Pickup Organized</span>
                            <span v-else>Courier Requested</span>
                        </v-alert>
                        <v-alert v-else-if="item.isReadyToOrderCourier" type="warning" dense shaped class="ma-0 pa-1">
                            <span v-if="item.isRequestPickup">Ready For Pickup</span>
                            <span v-else>Ready To Courier</span>
                        </v-alert>
                        <v-alert v-else type="error" dense shaped class="ma-0 pa-1">
                            Not Ready Yet
                        </v-alert>
                        <BT-Btn
                            v-if="item.isBuyerCourier && !item.isOrdered && item.isReadyToOrderCourier"
                            class="mt-2"
                            inline
                            label="Organize Courier"
                            rightIcon="mdi-arrow-right"
                            small
                            text />
                    </div>
                    <div v-else>
                        <v-alert v-if="item.isOrdered" type="success" dense shaped class="ma-0 pa-1">
                            <span v-if="item.isRequestPickup">Pickup Organized</span>
                            <span v-else>Courier Requested</span>
                        </v-alert>
                        <v-alert v-else-if="item.isReadyToOrderCourier" type="warning" dense shaped class="ma-0 pa-1">
                            <span v-if="item.isRequestPickup">Ready For Pickup</span>
                            <span v-else>Ready To Courier</span>
                        </v-alert>
                        <v-alert v-else type="error" dense shaped class="ma-0 pa-1">
                            Not Ready Yet
                        </v-alert>
                    </div>
                </v-col> -->
                <v-col v-if="item.isOrdered" cols="12" sm="6" class="py-1">
                    <v-divider class="mb-1" />
                    <BT-Preload
                        :disabled="!item.isOrdered"
                        :label="!item.isOrdered ? 'No Delivery Info Yet' : 'Open Delivery Info'">
                        <BT-Blade-Items
                            :customURL="`/ByConsignmentID/${item.id}`"
                            hideBladeHeader
                            hideHeader
                            navigation="receivals">
                            <template v-slot:body="{ allItems }">
                                <v-row v-if="isArrayOfLength(allItems, 1)" no-gutters>
                                    <v-col cols="6">
                                        <BT-Field-Address v-if="allItems[0].isPickup"
                                            label="PICKUP FROM"
                                            v-model="allItems[0].departureLocation">
                                        </BT-Field-Address>
                                        <!-- <v-list-item v-if="allItems[0].isPickup" dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle></v-list-item-subtitle>
                                                <v-list-item-title>{{ allItems[0].courier.companyName }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item> -->
                                        <v-list-item v-else dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>COURIER</v-list-item-subtitle>
                                                <v-list-item-title>{{ allItems[0].courier.companyName }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="6" v-if="allItems[0].deliveredOn != null">
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Delivered By</v-list-item-subtitle>
                                                <v-list-item-title>
                                                    <BT-Entity
                                                        alternateText="Unknown"
                                                        navigation="public-users"
                                                        single
                                                        :itemValue="allItems[0].deliveredByUserID"
                                                        itemText="userName"
                                                        useLocalCache />
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Delivered On</v-list-item-subtitle>
                                                <v-list-item-title>{{ allItems[0].deliveredOn | toDayMonth }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Signed By</v-list-item-subtitle>
                                                <v-list-item-title>{{ allItems[0].receiverName }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="6" v-else-if="allItems[0].isPickup">

                                    </v-col>
                                    <v-col cols="6" v-if="allItems[0].releasedOn != null">
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Driver</v-list-item-subtitle>
                                                <v-list-item-title>
                                                    <BT-Entity
                                                        alternateText="Unknown"
                                                        navigation="public-users"
                                                        single
                                                        :itemValue="allItems[0].deliveredByUserID"
                                                        itemText="userName"
                                                        useLocalCache />
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Status</v-list-item-subtitle>
                                                <v-list-item-title>On the road at {{ allItems[0].releasedOn | toRelativeTime }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col v-else-if="allItems[0].releasedOn != null" cols="6">

                                    </v-col>
                                    <v-col v-if="allItems[0].deliveredOn != null" cols="12">
                                        proofs
                                    </v-col>
                                </v-row>
                            </template>
                        </BT-Blade-Items>
                    </BT-Preload>
                </v-col>

                <v-col cols="12" sm="6">
                    <v-divider class="my-1" />
                    <div class="px-4">Status</div>
                    <div v-if="item.isBuyerCourier" class="my-4">
                        <v-alert v-if="item.isOrdered" type="success" dense shaped class="ma-0 pa-1">
                            <span v-if="item.isRequestPickup">Pickup Organized</span>
                            <span v-else>You've requested a courier</span>
                        </v-alert>
                        <v-alert v-else-if="item.isReadyToOrderCourier" type="warning" dense shaped class="ma-0 pa-1">
                            <span v-if="item.isRequestPickup">Ready For Pickup</span>
                            <span v-else>Ready To Courier</span>
                        </v-alert>
                        <v-alert v-else type="error" dense shaped class="ma-0 pa-1">
                            Not Ready Yet
                        </v-alert>
                    </div>
                    <div v-else class="my-4">
                        <v-alert v-if="item.isOrdered" type="success" dense shaped class="ma-0 pa-1">
                            <span v-if="item.isRequestPickup">Pickup Organized</span>
                            <span v-else>Courier Requested By Supplier</span>
                        </v-alert>
                        <v-alert v-else-if="item.isReadyToOrderCourier" type="warning" dense shaped class="ma-0 pa-1">
                            <span v-if="item.isRequestPickup">Ready For Pickup</span>
                            <span v-else>Ready To Courier</span>
                        </v-alert>
                        <v-alert v-else type="error" dense shaped class="ma-0 pa-1">
                            Not Ready Yet
                        </v-alert>
                    </div>
                </v-col>
                <v-col v-if="item.isBuyerCourier" cols="12" sm="6">
                    <v-divider class="my-1" />
                    <BT-Preload
                        :disabled="!item.isBuyerCourier"
                        :label="!item.isBuyerCourier ? 'No Courier Info' : 'Open Courier Orders'">
                        <div class="px-4">Courier Orders</div>
                        <BT-Blade-Items
                            hideBladeHeader
                            hideHeader
                            navigation="courier-orders"
                            :onPullSuccessAsync="list => pullCourierOrders(item, list)"
                            :params="{ consignmentID: item.id, includeDetails: false }"
                            :refreshToggle="refreshCourierToggle">
                            <template v-slot:body="{ allItems }">
                                <v-card v-for="(order, index) in allItems" :key="index" class="mx-1 mb-1" :to="{ name: 'courier-order', params: { id: order.id }}">
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-img :src="companyLogoURL(item.sellerID)" class="mx-auto">
                                                <template v-slot:placeholder>
                                                    <v-icon color="primary--text" >mdi-account</v-icon>
                                                </template>
                                            </v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ order.seller.companyName }}</v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span v-if="order.fulfilledOn != null">Fulfilled</span>
                                                <span v-else-if="order.isBilled">Billed</span>
                                                <span v-else-if="order.isPlanned">Movement Planned</span>
                                                <span v-else-if="order.isApproved === true">Approved</span>
                                                <span v-else-if="order.isApproved === false">Rejected</span>
                                                <span v-else-if="order.isApproved == null">Awaiting Courier Approval</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action-text>#{{ order.courierOrderNumber }}</v-list-item-action-text>
                                    </v-list-item>
                                </v-card>
                                <v-card v-if="canAddCourierOrder" class="mx-1 mb-1" @click="openToggle = !openToggle">
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <v-icon>mdi-plus</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>Request Courier</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                                <BT-Dialog
                                    cardTextClass="ma-0 pa-0"
                                    :getOnOpen="item => getDialogOrder(item, allItems)"
                                    height="400"
                                    hideButton
                                    :item="item"
                                    label="New Courier Order"
                                    @ok="postCourierOrder"
                                    :openToggle="openToggle"
                                    width="600">
                                    <template v-slot="{ item }">

                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <BT-Square-Check
                                                    falseClass="success--text"
                                                    falseIcon="mdi-account"
                                                    isEditing
                                                    :label="item.isSelfCourier ? 'I Will Courier' : 'Use Another Courier'"
                                                    outlined
                                                    trueIcon="mdi-account"
                                                    v-model="item.isSelfCourier" />
                                            </v-col>
                                            <v-col cols="12">
                                                <BT-Select-List-Box
                                                    v-if="!item.isSelfCourier"
                                                    height="200"
                                                    isEditing
                                                    isSelecting
                                                    itemValue="sellerID"
                                                    itemText="seller.companyName"
                                                    label="Courier"
                                                    navigation="couriers"
                                                    :onPullSuccessAsync="l => pullCouriers(item, l)"
                                                    :searchProperties="['buyer.companyName']"
                                                    v-model="item.sellerID" />
                                            </v-col>
                                            <v-col cols="12">
                                                <BT-Date-Picker
                                                    v-model="item.dueDate"
                                                    label="Due Date" />
                                            </v-col>
<!-- 
                                            <v-divider class="my-1" />
                                            <div class="mx-4">Departure</div>
                                             -->
                                            <v-col cols="7">
                                                <BT-Field-Address
                                                    label="FROM"
                                                    v-model="item.departureLocation" />
                                            </v-col>
                                            <v-col cols="5">
                                                <BT-Square-Check
                                                    falseClass="success--text"
                                                    falseIcon="mdi-map-marker"
                                                    isEditing
                                                    :label="item.preferStartPickup ? 'Pickup from here' : 'Take to courier'"
                                                    outlined
                                                    trueIcon="mdi-map-marker"
                                                    v-model="item.preferStartPickup" />
                                            </v-col>
<!--                                             
                                            <v-divider class="my-1" />
                                            <div class="mx-4">Destination</div> -->

                                            <v-col cols="7">
                                                <BT-Field-Address
                                                    label="TO"
                                                    v-model="item.destinationLocation" />
                                            </v-col>
                                            <v-col cols="5">
                                                <BT-Square-Check
                                                    falseClass="success--text"
                                                    falseIcon="mdi-map-marker"
                                                    isEditing
                                                    :label="item.preferEndDropoff ? 'Please deliver' : 'Pickup from courier'"
                                                    outlined
                                                    trueIcon="mdi-map-marker"
                                                    v-model="item.preferEndDropoff" />
                                            </v-col>
                                            <v-col cols="12">
                                                <BT-Field-Text-Area
                                                    isEditing
                                                    label="Message"
                                                    v-model="item.requesterMessage" />
                                            </v-col>
                                        </v-row>

                                        <!--<BT-Select
                                            v-if="!item.isSelfCourier"
                                            navigation="couriers"
                                            itemValue="sellerID"
                                            itemText="seller.companyName"
                                            label="Courier"
                                            v-model="item.sellerID" />

                                        <BT-Toggle                        
                                            v-model="item.preferStartPickup"
                                            label="Departure"
                                            trueText="Please Pickup"
                                            falseText="I Will Drop Off" />
                                        
                                        <BT-Toggle
                                            v-model="item.preferEndDropoff"
                                            label="Destination"
                                            trueText="Please Drop Off"
                                            falseText="Pickup from Courier" />
                                        
                                        <v-textarea
                                            v-model="item.requesterMessage"
                                            label="Message"
                                            rows="2" /> -->

                                    </template>
                                </BT-Dialog>
                            </template>
                        </BT-Blade-Items>
                    </BT-Preload>
                </v-col>
            </v-row>
        </template>
    </BT-Blade-Item>
</template>

<script>
import { isLengthyArray } from '~helpers';

export default {
    name: 'Supplier-Consignment',
    data: function() {
        return {
            canAddCourierOrder: false,
            loadingMsg: null,
            openToggle: false,
            refreshCourierToggle: false
        }
    },
    components: {
        // BTLocation: () => import('~components/BT-Location.vue'),
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        // BTToggle: () => import('~components/BT-Toggle.vue')
    },
    methods: {
        getDialogOrder(item, orders) {
            var departureOptions = [];

            if (orders.length > 0) {
                //include departure options from courier order destinations
                departureOptions.push(item.departureLocation);

                for (let i = 0; i < orders.length; i++) {
                    const order = orders[i];
                    if (order.destinationLocation != null) {
                        departureOptions.push(order.destinationLocation);
                    }
                }
            }

            return {
                sellerID: null,
                isSelfCourier: false,
                preferStartPickup: true,
                preferEndDropoff: true,
                requesterMessage: null,
                courierConsignmentID: item.id,
                departureOptions: departureOptions,
                departureLocationID: isLengthyArray(orders) ? null : item.departureLocationID,
                departureLocation: isLengthyArray(orders) ? null : item.departureLocation,
                destinationLocationID: item.destinationLocationID,
                destinationLocation: item.destinationLocation,
                dueDate: item.dueDate,
                consignment: item,
            };
        },
        pullCouriers(item, list) {
            if (!this.isLengthyArray(list)) {
                item.isSelfCourier = true;
            }

            return list;
        },
        pullCourierOrders(item, orders) {
            //check if any are last leg
            this.canAddCourierOrder = !orders.some(x => x.requestedDestinationLocationID == item.destinationLocationID || x.destinationLocationID == item.destinationLocationID);
            return orders;
        },
        async postCourierOrder(item) {
            if (item.sellerID || item.isSelfCourier) {
                var postObj = {
                    sellerID: item.isSelfCourier ? this.companyAccountID() : item.sellerID,
                    requesterMessage: item.requesterMessage,
                    courierConsignmentID: item.courierConsignmentID,
                    requestedDepartureLocationID: item.departureLocationID,                   
                    requestedDestinationLocationID: item.destinationLocationID,                    
                    dueDate: item.dueDate,
                    preferStartPickup: item.preferStartPickup,
                    preferEndDropoff: item.preferEndDropoff
                };

                try {
                    this.loadingMsg = 'Creating';
                    await this.$BlitzIt.store.post('courier-orders', postObj);
                    this.refreshCourierToggle = !this.refreshCourierToggle;
                }
                finally {
                    this.loadingMsg = null;
                }
            }
            
        }
    }
}
</script>